@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", Helvetica, sans-serif;
}

.male-icons {
  height: 30px; /* You can adjust the height as needed */
  color: lightblue;
}

.female-icons {
  height: 30px; /* You can adjust the height as needed */
  color: palevioletred;
}

.arrow-right-icon {
  height: inherit;
  color: darkred;
  transition: transform 0.3s ease-in-out; /* Define the transition property */
}

/* @keyframes moveRight { */
/* 0% { */
/* transform: translateX(0); Start at the initial position */
/* } */
/* 100% { */
/* transform: translateX(10px); Move 10 pixels to the right */
/* } */
/* } */

.arrow-right-icon:hover {
  transform: translateX(10px);
}

.arrow-left-icon {
  height: inherit;
  color: darkred;
  transition: transform 0.3s ease-in-out; /* Define the transition property */
}

/* @keyframes moveLeft { */
/* 0% { */
/* transform: translateX(0); Start at the initial position */
/* } */
/* 100% { */
/* transform: translateX(-10px); Move 10 pixels to the left */
/* } */
/* } */

.arrow-left-icon:hover {
  transform: translateX(-10px);
}

button:focus .arrow-right-icon,
button:focus .arrow-left-icon {
  color: white;
}

.styled-input {
  outline: auto;
  outline-color: #630a08;
  text-align: center;
  width: 100%; /* Use 100% width for responsiveness */
  max-width: 300px; /* Set a maximum width if needed */
  margin: auto;
  padding: 0.75em;
  font-size: 1em;
  font-weight: bold;
  color: #4f0b0a;
  background-color: transparent;
  display: flex;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    font-size: 0.9em; /* Adjust font size for smaller screens */
  }

  /* Additional adjustments for even smaller screens */
  @media (max-width: 480px) {
    font-size: 0.8em; /* Further adjust font size */
  }

  /* Add more media queries as needed */
}

/* Styles for the range slider track */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background-color: gray;
  outline: none;
  opacity: 8;
  transition: opacity 0.2s;
}

/* Styles for the range slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: #900000 3px solid;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Change thumb color on hover */
input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #900000;
  border: #900000 1px solid;
}

/* Change thumb color on active */
input[type="range"]:active::-webkit-slider-thumb {
  background-color: #900000;
  border: #900000 1px solid;
}

/* Change track color when thumb is pressed */
input[type="range"]:active {
  opacity: 1;
}
